import * as React from 'react'
import KnowledgeBaseLayout from '../layouts/KnowledgeBaseLayout'

import styled from 'styled-components'
import colors from '../layouts/common/colors'
import { graphql, useStaticQuery } from 'gatsby'

const FeatureTable = styled.table`
  table-layout: fixed;

  th, td {
    padding: 0.5em;
    text-align: left;
    border-bottom: 1px solid ${colors.grey};
  }

  th {
    &.feature {
      width: 200px;
    }

    &.status {
      width: 150px;
    }
  }

  td {
    vertical-align: top;
    border-bottom: 1px solid ${colors.grey};

    &.Finished {
      color: ${colors.green};
    }
  }
`

const Roadmap : React.FunctionComponent = () => {

  const data = useStaticQuery(graphql`
    query Roadmap {
      roadmapJson {
        en {
          content
          status
          title
        }
      }
    }`
  )

  return (
    <KnowledgeBaseLayout leftBar={true}>
      <main>
      <h1>Roadmap 2021</h1>
        <p>Below is the roadmap for BPM Online. Please note that this roadmap is indicative and priorities can shift based on external and internal factors. The roadmap serves as an indicator for the level of priority certain features have. Some features will take a long time to develop and test and as such no guarantee can be given on the delivery dates.</p>

        <FeatureTable>
          <thead>
            <tr>
              <th className="feature">Feature</th>
              <th>Description</th>
              <th className="status">Status</th>
            </tr>
          </thead>
          <tbody>
          { data.roadmapJson.en.map((feature: any) => (
            <tr key={feature.title}>
              <td>{ feature.title }</td>
              <td dangerouslySetInnerHTML={{__html: feature.content}}></td>
              <td className={feature.status}>{ feature.status }</td>
            </tr>
          ))}
          </tbody>
        </FeatureTable>
      </main>
    </KnowledgeBaseLayout>
  )
}

export default Roadmap;
